import React from 'react';
import PublicRoute from 'src/containers/PublicRoute';
import PublicPage from 'src/templates/PublicPage';
import { Helmet } from 'react-helmet'

import { Consumer } from 'src/services/store';
import LandingSection from './containers/LandingSection';
import NumbersSections from './containers/NumbersSection';
import TenantSection from './containers/TenantSection';
import TeamSection from './containers/TeamSection';
import MediasSection from './containers/MediasSection';
import UsersSection from './containers/UsersSection';
import InformationsSection from './containers/InformationsSection';

import { COLORS } from 'src/stylesheet/colors'


class LandingPageExit extends React.Component {

  render() {
    return (
    <div>
      {/* Page intentionnellement laissée vide */}
    </div>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Consumer>
    {ctx => <PublicRoute component={LandingPageExit} {...props} data={ctx} ref={ref} />}
  </Consumer>
));
